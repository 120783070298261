import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import ability from './ability'
import * as utils from './utils'

export const useUtils = () => ({
  ...utils,
})

Vue.use(abilitiesPlugin, ability)
export const _ = null
